var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-lg-5", attrs: { fluid: "" } },
    [
      _c("app-header", {
        attrs: { title: _vm.title, breadcrumbs: _vm.breadcrumbs }
      }),
      _vm.isLoaded
        ? _c(
            "v-card",
            { staticClass: "fill-height", attrs: { flat: "", outlined: "" } },
            [
              _vm._l(_vm.organizationChart, function(tree, index) {
                return _c("app-tree", {
                  key: index,
                  ref: "scaleTree",
                  refInFor: true,
                  staticClass: "fill-height",
                  attrs: { dataset: tree, "link-style": "straight" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "node",
                        fn: function(ref) {
                          var node = ref.node
                          return [
                            _c("tree-node", {
                              attrs: { data: node },
                              on: {
                                click: function($event) {
                                  _vm.drawer = true
                                }
                              }
                            })
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                })
              }),
              _c(
                "v-footer",
                {
                  staticClass: "font-weight-medium",
                  attrs: {
                    color: "rgba(0, 0, 0, 0.1)",
                    absolute: "",
                    padless: ""
                  }
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "12" } },
                    _vm._l(_vm.scalingButtons, function(btn, index) {
                      return _c(
                        "v-btn",
                        {
                          key: index,
                          staticClass: "mx-2",
                          attrs: { fab: "", "x-small": "" },
                          on: {
                            click: function($event) {
                              return _vm.handleScaleChange(btn.action)
                            }
                          }
                        },
                        [
                          btn.icon
                            ? _c("v-icon", { attrs: { dark: "" } }, [
                                _vm._v(_vm._s(btn.icon))
                              ])
                            : _vm._e(),
                          btn.text
                            ? _c("span", [_vm._v(_vm._s(btn.text))])
                            : _vm._e()
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }