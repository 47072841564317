var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "m-5",
      attrs: { "min-width": "180", "max-width": "180", elevation: 2 }
    },
    [
      _c("v-img", {
        staticClass: "transition-ease-in-out",
        attrs: {
          src: _vm.getFileUrl(_vm.userNode.avatar),
          "aspect-ratio": "1"
        },
        on: {
          error: function($event) {
            _vm.hasError = true
          }
        },
        scopedSlots: _vm._u([
          {
            key: "placeholder",
            fn: function() {
              return [
                _c(
                  "v-row",
                  {
                    staticClass: "primary fill-height ma-0",
                    attrs: {
                      "no-gutters": "",
                      align: "center",
                      justify: "center"
                    }
                  },
                  [
                    _vm.hasError || _vm._.isEmpty(_vm.userNode.avatar)
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "name-initials-size white--text headline"
                          },
                          [_vm._v(" " + _vm._s(_vm.userNode.initials) + " ")]
                        )
                      : _vm._e()
                  ]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "v-card-title",
        {
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.$router.push({
                name: "UserPublicProfile",
                params: { uuid: _vm.userNode.id }
              })
            }
          }
        },
        [
          _c("v-hover", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var hover = ref.hover
                  return [
                    _c(
                      "span",
                      {
                        class: hover ? "deep-orange--text lighten-1 h4" : "h4"
                      },
                      [_vm._v(_vm._s(_vm.userNode.name))]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("v-card-subtitle", {
        staticClass: "overline",
        domProps: { textContent: _vm._s(_vm.userNode.jobTitle) }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }